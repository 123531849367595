import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function ProductSmokingHero() {
  return (
    <Container
      component='section'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        my: 9,
      }}
    >
      <Button
        sx={{
          border: '4px solid currentColor',
          borderRadius: 0,
          height: 'auto',
          py: 2,
          px: 5,
        }}
      >
        <Typography variant='h4' component='span'>
          <Link component={RouterLink} underline='none' to='/contact/'>
            Got any questions?
          </Link>
        </Typography>
      </Button>
      <Typography variant='subtitle1' sx={{ my: 3 }}>
        We are here to help. Get in touch!
      </Typography>
      <Box
        component='img'
        src='/producBuoy.svg'
        alt='buoy'
        sx={{ width: 60 }}
      />
    </Container>
  );
}

export default ProductSmokingHero;
