import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 3,
};

const mobileLink = {
  fontSize: 16,
  color: 'common.black',
  display: 'block',
  ml: 3,
};

const drawerWidth = 240;
const navItems = ['Home', 'Services', 'About', 'Contact'];

export default function AppAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        <ListItem>
          <ListItemButton component='a' href='/'>
            <ListItemText primary='Home' />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component='a' href='/services/'>
            <ListItemText primary='Services' />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component='a' href='/about/'>
            <ListItemText primary='About' />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component='a' href='/contact/'>
            <ListItemText primary='Contact' />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        component='nav'
        sx={{ backgroundColor: 'black', paddingBottom: 1 }}
      >
        <Toolbar sx={{ height: 40 }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant='h6'
            component='div'
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <Box
              component='img'
              sx={{
                height: 90,
                paddingTop: 2,
              }}
              alt='YETX logo.'
              src='/logo-custom.png'
            />
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Link
              variant='h6'
              underline='none'
              component={RouterLink}
              to='/'
              sx={rightLink}
            >
              Home
            </Link>
            <Link
              variant='h6'
              underline='none'
              component={RouterLink}
              to='/services/'
              sx={rightLink}
            >
              Services
            </Link>
            <Link
              variant='h6'
              underline='none'
              component={RouterLink}
              to='/about/'
              sx={rightLink}
            >
              About
            </Link>
            <Link
              variant='h6'
              underline='none'
              component={RouterLink}
              to='/contact/'
              sx={rightLink}
            >
              Contact
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component='nav'>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
