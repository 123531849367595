import emailjs from '@emailjs/browser';
import { Alert, Snackbar, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import Typography from './modules/components/Typography';
import FormButton from './modules/form/FormButton';
import FormFeedback from './modules/form/FormFeedback';
import RFTextField from './modules/form/RFTextField';
import { email, phone, required } from './modules/form/validation';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import AppForm from './modules/views/AppForm';
import withRoot from './modules/withRoot';

function Contact() {
  const [sent, setSent] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const validate = (values: { [index: string]: string }) => {
    const errors = required(
      ['firstName', 'lastName', 'email', 'message'],
      values
    );

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    if (!errors.phone) {
      const phoneError = phone(values.phone);
      if (phoneError) {
        errors.phone = phoneError;
      }
    }

    return errors;
  };

  const handleSubmit = (values: any, form: any) => {
    emailjs
      .send('service_hstq89u', 'template_hll2x4w', values, '-clDMbOXEK4Rn6Tkj')
      .then(
        (result) => {
          // console.log(result.text);
          setSent(true);
          setOpenAlert(true);
          form.restart();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Box
            component='img'
            src='/productCurvyLines.png'
            alt='curvy lines'
            sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
          ></Box>
          <Typography variant='h3' gutterBottom marked='center' align='center'>
            Contact us
          </Typography>
          <Typography variant='body2' align='center'>
            Please fill out the form below to send us an email and we will get
            back to you as soon as possible.
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit: handleSubmit2, submitting }) => (
            <Box
              component='form'
              onSubmit={handleSubmit2}
              noValidate
              sx={{ mt: 6 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    autoFocus
                    component={RFTextField}
                    disabled={submitting}
                    autoComplete='given-name'
                    fullWidth
                    label='First name'
                    name='firstName'
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={RFTextField}
                    disabled={submitting}
                    autoComplete='family-name'
                    fullWidth
                    label='Last name'
                    name='lastName'
                    required
                  />
                </Grid>
              </Grid>
              <Field
                autoComplete='phone'
                component={RFTextField}
                disabled={submitting}
                fullWidth
                label='Phone'
                margin='normal'
                name='phone'
              />
              <Field
                autoComplete='email'
                component={RFTextField}
                disabled={submitting}
                fullWidth
                label='Email'
                margin='normal'
                name='email'
                required
              />
              <Field
                component={RFTextField}
                disabled={submitting}
                fullWidth
                label='Message'
                margin='normal'
                name='message'
                multiline
                maxRows={4}
                required
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting}
                color='secondary'
                fullWidth
              >
                {submitting ? 'In progress…' : 'Send'}
              </FormButton>
            </Box>
          )}
        </Form>
      </AppForm>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          open={openAlert}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity='success'
            color='info'
            sx={{ width: '100%' }}
          >
            Thank you for reaching out to us. Our team will contact you soon!
          </Alert>
        </Snackbar>
      </Stack>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Contact);
