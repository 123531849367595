import * as React from 'react';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import ProductValues from './modules/views/ProductValues';
import withRoot from './modules/withRoot';
import Services from './Services';

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <ProductValues />
      {/* <ProductCategories /> */}
      <Services showFooter={false} />
      <ProductSmokingHero />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
