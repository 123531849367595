import { ListItem } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

const backgroundImage = '/pexels-suzy-hazelwood-1629236.jpg';
// "https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400&q=80";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt='increase priority'
      />
      <Typography color='inherit' align='center' variant='h4' marked='center'>
        Transforming your digital world with innovative IT solutions
      </Typography>
      <Typography
        color='inherit'
        align='center'
        variant='h5'
        sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
      >
        <ListItem
          sx={{
            padding: 0,
            textAlign: 'left',
            listStyleType: 'none',
            display: 'list-item',
          }}
        >
          Maximize Efficiency and Productivity with Tailored Technology
          Services.
        </ListItem>
        <ListItem
          sx={{
            padding: 0,
            textAlign: 'left',
            listStyleType: 'none',
            display: 'list-item',
          }}
        >
          Experience Unparalleled Support and Expertise with Our IT Solutions.
        </ListItem>
      </Typography>
      <Button
        color='secondary'
        variant='contained'
        size='large'
        component={RouterLink}
        to='/services/'
        sx={{ minWidth: 200 }}
      >
        Learn more
      </Button>
      <Typography variant='body2' color='inherit' sx={{ mt: 2 }}>
        Discover the experience
      </Typography>
    </ProductHeroLayout>
  );
}
