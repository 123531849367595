import { Container, Grid, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import Typography from './modules/components/Typography';
import { AboutUsDescription } from './modules/constants';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import withRoot from './modules/withRoot';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function About() {
  return (
    <>
      <AppAppBar />
      <Container component='section' sx={{ mt: 15, mb: 4 }}>
        <Typography variant='h4' marked='center' align='center' component='h2'>
          About us
        </Typography>
        <Box
          component='img'
          src='/productCurvyLines.png'
          alt='curvy lines'
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        ></Box>
        <Grid item xs={12}>
          <Box sx={item}>
            <Typography
              variant='h5'
              sx={{ marginTop: 5, whiteSpace: 'pre-line' }}
            >
              {AboutUsDescription}
            </Typography>
          </Box>
        </Grid>
      </Container>
      <AppFooter />
    </>
  );
}

export default withRoot(About);
