import CancelIcon from '@mui/icons-material/Cancel';
import {
  Button,
  Drawer,
  Grid,
  IconButton,
  Link,
  SxProps,
  Theme,
} from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from './modules/components/Typography';
import { GetServiceDescription } from './modules/constants';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import withRoot from './modules/withRoot';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '40vh',
  [theme.breakpoints.down('md')]: {
    width: '100% !important',
    height: 100,
  },
  '&:hover': {
    zIndex: 1,
  },
  '&:hover .imageBackdrop': {
    opacity: 0.15,
  },
  '&:hover .imageMarked': {
    opacity: 0,
  },
  '&:hover .imageTitle': {
    border: '4px solid currentColor',
  },
  '& .imageTitle': {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  '& .imageMarked': {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

const images = [
  {
    url: '/staffing.jpg?auto=format&fit=crop&w=400&q=80',
    title: 'Staffing and Career Consultation',
    width: '50%',
  },
  {
    url: '/project-management.jpg?auto=format&fit=crop&w=400&q=80',
    title: 'Project Management and Business Analytics',
    width: '50%',
  },

  {
    url: '/coaching-consulting.jpg?auto=format&fit=crop&w=400&q=80',
    title: 'International Students Consulting',
    width: '35%',
  },
  {
    url: '/software-development.jpg?auto=format&fit=crop&w=400&q=80',
    title: 'Software development',
    width: '30%',
  },
  {
    url: '/resume.jpg?auto=format&fit=crop&w=400&q=80',
    title: 'Resume Refinement',
    width: '35%',
  },
];

interface ServicesProps {
  showFooter: boolean;
}

function Services({ showFooter }: ServicesProps) {
  const [bottomDrawerState, setBottomDrawerState] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState('');

  const toggleDrawer =
    (serviceTitle: string, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setBottomDrawerState(open);
      setSelectedService(serviceTitle);
    };

  const list = (serviceTitle: string) => {
    return (
      <Box
        sx={{ width: 'auto' }}
        role='presentation'
        onClick={toggleDrawer('', false)}
        onKeyDown={toggleDrawer('', false)}
      >
        <Box
          component={'div'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
          }}
        >
          <IconButton
            color='primary'
            aria-label='upload picture'
            component='label'
          >
            <CancelIcon />
          </IconButton>
        </Box>
        <Container
          sx={{ mt: 5, mb: 30, display: 'flex', position: 'relative' }}
        >
          <Box
            component='img'
            src='/productCurvyLines.png'
            alt='curvy lines'
            sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
          />

          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Box sx={item}>
                <Typography
                  variant='h4'
                  marked='center'
                  align='center'
                  component='h2'
                >
                  {serviceTitle}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={item}>
                <Typography variant='h5' sx={{ marginTop: 5 }}>
                  {GetServiceDescription(serviceTitle)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={item}>
                <Button
                  sx={{
                    border: '4px solid currentColor',
                    borderRadius: 0,
                    height: 'auto',
                    py: 2,
                    px: 5,
                    marginTop: 10,
                  }}
                >
                  <Typography variant='h5' component='span'>
                    <Link
                      component={RouterLink}
                      underline='none'
                      to='/contact/'
                    >
                      Contact us Today
                    </Link>
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  return (
    <>
      <AppAppBar />
      <Container component='section' sx={{ mt: 15, mb: 4 }}>
        <Typography variant='h4' marked='center' align='center' component='h2'>
          Our Services
        </Typography>
        {showFooter && (
          <Box
            component='img'
            src='/productCurvyLines.png'
            alt='curvy lines'
            sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
          ></Box>
        )}
        <Box sx={{ mt: 8, display: 'flex', flexWrap: 'wrap' }}>
          {images.map((image) => (
            <ImageIconButton
              key={image.title}
              style={{
                width: image.width,
              }}
              onClick={toggleDrawer(image.title, true)}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center 40%',
                  backgroundImage: `url(${image.url})`,
                }}
              />
              <ImageBackdrop className='imageBackdrop' />
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'common.white',
                }}
              >
                <Typography
                  component='h3'
                  variant='h6'
                  color='inherit'
                  className='imageTitle'
                >
                  {image.title}
                  <div className='imageMarked' />
                </Typography>
              </Box>
            </ImageIconButton>
          ))}
        </Box>
        <Drawer
          anchor='bottom'
          open={bottomDrawerState}
          onClose={toggleDrawer('', false)}
        >
          {list(selectedService)}
        </Drawer>
      </Container>
      {showFooter && <AppFooter />}
    </>
  );
}

export default withRoot(Services);
