import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Avatar, Grid, IconButton } from '@mui/material';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import * as React from 'react';
import Typography from '../components/Typography';
function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color='inherit' href='https://yetxsolutions.com/'>
        Yetx Solutions
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}
function Address() {
  return (
    <React.Fragment>
      <Typography ml={3} mt={1} fontSize={13}>
        {'Fairfax, VA 22030'}
        <LocationOnIcon sx={{ fontSize: 'small' }} />
      </Typography>
    </React.Fragment>
  );
}

export default function AppFooter() {
  return (
    <Typography
      component='footer'
      sx={{ display: 'flex', bgcolor: 'secondary.light' }}
    >
      <Container
        component='section'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          my: 9,
        }}
      >
        <Grid container justifyContent='center'>
          <Grid item>
            <Grid
              container
              direction='column'
              justifyContent='flex-end'
              spacing={2}
              sx={{ height: 120 }}
            >
              <Grid item sx={{ display: 'flex' }}>
                <Avatar sx={{ bgcolor: 'black', marginRight: 3 }}>
                  <IconButton
                    href='https://instagram.com/yetxsolutions?igshid=YmMyMTA2M2Y='
                    target='_blank'
                    sx={{ color: 'inherit' }}
                  >
                    <FacebookIcon />
                  </IconButton>
                </Avatar>
                <Avatar sx={{ bgcolor: 'black', marginRight: 3 }}>
                  <IconButton
                    href='https://instagram.com/yetxsolutions?igshid=YmMyMTA2M2Y='
                    target='_blank'
                    sx={{ color: 'inherit' }}
                  >
                    <InstagramIcon />
                  </IconButton>
                </Avatar>
                <Avatar sx={{ bgcolor: 'black', marginRight: 3 }}>
                  <IconButton
                    href='https://twitter.com/YetxSolutions'
                    target='_blank'
                    sx={{ color: 'inherit' }}
                  >
                    <TwitterIcon />
                  </IconButton>
                </Avatar>
              </Grid>

              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
            <Grid item sx={{ justifyContent: 'flex-end' }}>
              <Address />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
