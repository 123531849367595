export const StaffingAndCareerConsultationDescription =
  "We offer staffing services to help businesses find the right talent for their technology teams. We understand that the right people are essential for the success of any project, and our team works tirelessly to connect businesses with the best IT professionals in the industry. Whether it's short-term or long-term staffing needs, we provide customized solutions that align with our clients' goals and objectives.";

export const SoftwareDevelopmentDescription =
  'We offer comprehensive software development services to businesses of all sizes. We work with cutting-edge technology and methodologies to deliver innovative solutions that meet the unique needs of our clients. Our team of experienced developers is well-versed in a variety of programming languages and platforms, enabling us to develop solutions that are robust, scalable, and future-proof.';

export const ProjectManagementAndBusinessAnalyticsDescription =
  'We provide project management and business analytics services to help businesses streamline their operations and achieve their goals. Our team of experts uses data-driven insights and best practices to identify opportunities for growth and improvement, develop strategies, and execute them with precision. We have a proven track record of delivering successful projects on time and within budget, and we pride ourselves on our ability to drive measurable results for our clients.';

export const InternationalStudentsCoachingAndConsultingDescription =
  'We are dedicated to helping international students who are coming to pursue further studies in the US. We understand that the transition to a new country can be overwhelming, which is why we offer comprehensive consultation services to help you adapt and thrive in your new environment. Our team of experts will provide guidance on everything from cultural norms to financial stability, provide guidance and support in navigating the academic landscape, adapting to American culture, and planning for your future career.';

export const ResumeRefinementDescription =
  "Looking for a new job, but not sure how to make your resume stand out from the crowd? Let us help! Our team of experienced professionals is dedicated to providing top-notch resume building and refinement services to job candidates just like you. Whether you're starting from scratch or looking to polish up an existing resume, we can help you create a powerful document that showcases your skills, experience, and accomplishments in the best possible light.";

export function GetServiceDescription(serviceTitle: string) {
  switch (serviceTitle) {
    case 'Staffing and Career Consultation':
      return StaffingAndCareerConsultationDescription;
    case 'Software development':
      return SoftwareDevelopmentDescription;
    case 'Project Management and Business Analytics':
      return ProjectManagementAndBusinessAnalyticsDescription;
    case 'International Students Consulting':
      return InternationalStudentsCoachingAndConsultingDescription;
    case 'Resume Refinement':
      return ResumeRefinementDescription;
  }
}

export const AboutUsDescription = `At YETX Solutions, we are a team of expert IT consultants dedicated to helping businesses leverage technology to achieve their goals. With years of experience in the industry, we have a proven track record of delivering customized solutions that drive results.

Our mission is to empower our clients to harness the power of technology and stay ahead of the curve in an ever-evolving digital landscape. We work closely with businesses of all sizes and across industries, offering a wide range of services including staffing and career consultation, software development, project management, business analytics, and more.

At YETX, we believe that technology should be an enabler, not a barrier. With our expert guidance and support, businesses can unlock the full potential of their IT infrastructure and achieve their goals with confidence.`;
