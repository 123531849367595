import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import * as React from 'react';
import Typography from '../components/Typography';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component='section'
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        <Box
          component='img'
          src='/productCurvyLines.png'
          alt='curvy lines'
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/* <Box
                component='img'
                src='/productValues1.svg'
                alt='suitcase'
                sx={{ height: 55 }}
              /> */}
              <WbIncandescentOutlinedIcon sx={{ fontSize: 55 }} />
              <Typography variant='h6' sx={{ my: 5 }}>
                Innovation
              </Typography>
              <Typography variant='h5'>
                {
                  'We strive to stay ahead of the curve by constantly exploring and implementing new technologies and solutions.'
                }
                {/* {
                  'From the latest trendy boutique hotel to the iconic palace with XXL pool'
                }
                {
                  ', go for a mini-vacation just a few subway stops away from your home.'
                } */}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/* <Box
                component='img'
                src='/productValues2.svg'
                alt='graph'
                sx={{ height: 55 }}
              /> */}
              <Diversity3OutlinedIcon sx={{ fontSize: 55 }} />
              <Typography variant='h6' sx={{ my: 5 }}>
                Customer-centric approach
              </Typography>
              <Typography variant='h5'>
                {/* {
                  'Privatize a pool, take a Japanese bath or wake up in 900m2 of garden… '
                }
                {'your Sundays will not be alike.'} */}
                {
                  "Our clients' satisfaction is at the core of our business, and we work tirelessly to understand their unique needs and deliver tailored solutions that exceed their expectations."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/* <Box
                component='img'
                src='/productValues3.svg'
                alt='clock'
                sx={{ height: 55 }}
              /> */}
              <EngineeringOutlinedIcon sx={{ fontSize: 55 }} />
              <Typography variant='h6' sx={{ my: 5 }}>
                Expertise
              </Typography>
              <Typography variant='h5'>
                {/* {'By registering, you will access specially negotiated rates '}
                {'that you will not find anywhere else.'} */}
                {
                  'We take pride in our team of highly skilled professionals who bring a wealth of experience and knowledge to every project we undertake. We are committed to staying on top of the latest trends and best practices in IT to provide our clients with the best possible service.'
                }
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
